<template>
  <div>
    <b-tabs @change="onTabChange" v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
      <b-tab-item :label="listLabel">
        <b-field grouped class="box" position="is-centered">
          <regional-selector ref="regionalSelector" @select="regionalSelect" />
        </b-field>

        <b-table :data="displayRegionals" ref="table" :row-class="rowClass">
          <b-table-column v-slot="props" field="status" label="状态" width="60" centered>
            <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
          </b-table-column>
          <b-table-column #default="props" field="id" label="regional" width="40" numeric centered>{{ props.row.r}}</b-table-column>
          <b-table-column #default="props" label="平台" width="80">{{ pfTypesDict[props.row.pftype] }}</b-table-column>
          <b-table-column #default="props" field="name" label="名称" width="400">{{ props.row.name}}</b-table-column>
          <b-table-column #default="props" v-if="isAdmin" label="LoginKey" width="80">{{ loginKeysDict[props.row.loginkey] }}</b-table-column>
          <b-table-column #default="props" field="server" label="区服" width="60" centered>
            <span class="tag" :class="mjpType2Color(props.row.mjptype)">{{ mjpType2Name(props.row.mjptype) }}</span>
          </b-table-column>
          <b-table-column #default="props" label="操作">
            <div class="buttons">
              <button class="button is-primary" @click="onSelectClick(props.row.r)" :disabled="!canSelect(props.row)">选择</button>
              <button v-if="isAdmin" class="button is-warning" @click="onEditClick(props.row.r)">修改</button>
              <button v-if="isAdmin" class="button is-danger" :disabled="props.row.r <= 0" @click="onDeleteClick(props.row.r)">删除</button>
            </div>
          </b-table-column>
          <template #empty>
              <div class="has-text-centered">No records</div>
            </template>
        </b-table>
      </b-tab-item>
      <b-tab-item label="增加" v-if="isAdmin">
        <section>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="editName" placeholder="输入名称"></b-input>
          </b-field>
          <b-field horizontal label="Regional">
            <b-input v-model.number="editR" placeholder="输入regional"></b-input>
          </b-field>
          <b-field horizontal label="loginkey">
            <b-select v-model.number="editLoginkey">
              <option v-for="(v,k) in loginKeys" :key="k" :value="v.vid">{{v.name}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="平台">
            <b-select v-model.number="editPftype">
              <option v-for="v in pfTypes" :key="v.value" :value="v.value">{{v.text}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="editValue" rows="10" type="textarea" placeholder="配置"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch v-model.number="editStatus" true-value="1" false-value="5">{{editStatus == 1?'启用':'禁用'}}
            </b-switch>
          </b-field>
          <b-field grouped position="is-centered" >
          <button center class="button is-info" :disabled="!canSave" size="is-medium"
            @click="saveAddRegional()">保存</button>
          </b-field>
        </section>
      </b-tab-item>
      <b-tab-item label="编辑" :disabled="!inEdit" v-if="isAdmin">
        <section>
          <b-field horizontal label="Regional" style="text-align:left">
            <span>{{editR}}</span>
          </b-field>
          <b-field horizontal label="名称" width="100">
            <b-input v-model="editName" placeholder="输入名称"></b-input>
          </b-field>
          <b-field horizontal label="loginkey">
            <b-select v-model.number="editLoginkey">
              <option v-for="(v,k) in loginKeys" :key="k" :value="v.vid">{{v.name}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="平台">
            <b-select v-model.number="editPftype">
              <option v-for="v in pfTypes" :key="v.value" :value="v.value">{{v.text}}</option>
            </b-select>
          </b-field>
          <b-field horizontal label="配置">
            <b-input v-model="editValue" type="textarea" rows="10" placeholder="配置"></b-input>
          </b-field>
          <b-field horizontal label="状态" style="text-align:left">
            <b-switch v-model.number="editStatus" true-value="1" false-value="5">{{editStatus == 1?'启用':'禁用'}}
            </b-switch>
          </b-field>
          <b-field grouped position="is-centered" >
            <button :disabled="!canSave" class="button is-danger" size="is-medium" @click="saveEditorRegional()">保存</button>
          </b-field>
        </section>
      </b-tab-item>
    </b-tabs>
  </div>
</template>
<script>
import toml from '@iarna/toml'

import cache from '@/core/cache'
import type from '@/core/type'
import RegionalSelector from '@/components/RegionalSelector'
import { mjpType2Color, mjpType2Name } from '@/core/util'

export default {
  name: 'regionallist',
  components: { RegionalSelector },
  data () {
    return {
      tabIndex: 0,
      inEdit: false,
      regional: null,
      selectedRegional: null,
      displayRegionals: [],
      loginKeys: [],
      loginKeysDict: {},
      pfTypes: [],
      pfTypesDict: {},
      isAdmin: false,
      editName: null,
      editR: null,
      editValue: null,
      editStatus: 1,
      editLoginkey: null,
      editPftype: null
    }
  },
  mounted () {
    this.updateTfTypes()
    this.$refs.regionalSelector.getRecords()
    // 在 regional 选择界面点击选择按钮的时候，切换到列表 tab
    this.hub.$on('regional-click', this.onRegionalClick)
  },
  destroyed () {
    // 取消监听 click
    this.hub.$off('regional-click', this.onRegionalClick)
  },
  computed: {
    // 封装搜索数据
    listLabel () {
      return `区服列表(${this.displayRegionals.length})`
    },
    canSave () {
      return this.editName &&
          this.editR !== null &&
          this.editStatus !== null &&
          this.editValue &&
          this.editLoginkey &&
          this.editPftype !== null
    }
  },
  methods: {
    updateTfTypes () {
      this.pfTypes = type.pfTypes()
      console.log('this.pfTypes %o', this.pfTypes)
      this.pfTypesDict = {}
      for (const pft of this.pfTypes) {
        this.pfTypesDict[pft.value] = pft.text
      }
      console.log('this.pfTypesDict %o', this.pfTypesDict)
    },
    canSelect (row) {
      return row.status === 1 && (this.selectedRegional === null || this.selectedRegional.r !== row.r)
    },
    rowClass (row, index) {
      if (this.selectedRegional && row.r === this.selectedRegional.r) {
        const rowColor = this.mjpType2Color(row.mjptype)
        return rowColor
      }
      return null
    },
    mjpType2Color (mjpType) {
      return mjpType2Color(mjpType)
    },
    mjpType2Name (mjpType) {
      return mjpType2Name(mjpType)
    },
    regionalSelect (regionalList) {
      console.warn('regionalSelect')
      this.displayRegionals = regionalList
      this.updateByAdmin()
      this.updateSelectedRegional()
    },
    updateSelectedRegional () {
      console.warn('updateSelecteRegional')
      const regionalInCache = cache.getRegional()
      // 若缓存中的就是当前选择的，就跳过更新
      if (this.selectedRegional !== null && regionalInCache !== null && this.selectedRegional.r === regionalInCache.r) {
        return
      }
      this.selectedRegional = regionalInCache
      this.changeRegional()
    },
    updateByAdmin () {
      const userType = cache.getUserType()
      this.isAdmin = userType < 55
      if (this.isAdmin) {
        this.getLoginKey()
      }
    },
    async getLoginKey () {
      const result = await this.mjp.get2({
        url: '/rsi/regional/loginkey/get/all/',
        timeout: 60000
      })
      this.loginKeys = result.loginkeys
      this.loginKeysDict = {}
      for (const lk of this.loginKeys) {
        this.loginKeysDict[lk.vid] = lk.name
      }
    },
    async onSelectClick (r) {
      try {
        const rdata = await this.mjp.get2({ url: '/rsi/regional/get/', query: { r, mergevo: 2 } })
        this.selectedRegional = rdata.regional
        cache.setRegional(this.selectedRegional)
        console.warn('onSelectClick')
        this.changeRegional()
      } catch (e) {
        console.log(e)
      }
    },
    changeRegional () {
      console.warn('changeRegional')
      if (this.selectedRegional !== null) {
        this.hub.$emit('regional-change', this.selectedRegional.r)
      }
    },
    onEditClick (r) {
      this.getRegional(r)
    },
    async getRegional (r) {
      try {
        const result = await this.mjp.get2({ url: '/rsi/regional/get/', query: { r: r } })

        this.editR = result.regional.r
        this.editName = result.regional.name
        this.editLoginkey = result.regional.loginkey
        this.editPftype = result.regional.pftype
        this.editStatus = result.regional.status
        this.editValue = result.regional.value

        this.tabIndex = 2
        this.inEdit = true
      } catch (e) {
        console.log(e)
      }
    },
    testToml (t) {
      try {
        toml.parse(t)
        return true
      } catch (e) {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'TOML格式错误',
          position: 'is-bottom',
          type: 'is-danger'
        })
        console.log(e)
        return false
      }
    },
    async onEditSave (r, url) {
      try {
        await this.mjp.post(url, r)
        this.$buefy.toast.open({
          message: '操作成功！',
          type: 'is-success'
        })
        this.tabIndex = 0
        this.$refs.regionalSelector.getRecords()
      } catch (e) {
        console.log(e)
      }
    },
    getValues () {
      return {
        r: this.editR,
        name: this.editName,
        pftype: this.editPftype,
        loginkey: this.editLoginkey,
        status: this.editStatus,
        value: this.editValue
      }
    },
    saveEditorRegional () {
      if (this.testToml(this.editValue)) {
        this.onEditSave(this.getValues(), '/rsi/regional/edit/')
      }
    },
    saveAddRegional () {
      if (this.testToml(this.editValue)) {
        this.onEditSave(this.getValues(), '/rsi/regional/add/')
      }
    },
    onDeleteClick (r) {
      try {
        this.$buefy.dialog.confirm({
          message: '确认删除？',
          type: 'is-danger',
          onConfirm: async () => {
            await this.mjp.post('/rsi/regional/del/', { r: r })
            this.$refs.regionalSelector.getRecords()
            this.$buefy.toast.open({
              message: '删除成功',
              type: 'is-success'
            })
          }
        })
      } catch (e) {
        console.log(e)
        this.$buefy.toast.open({
          message: '删除失败',
          type: 'is-danger'
        })
      }
    },
    onTabChange (to) {
      if (to === 1) {
        this.editR = null
        this.editName = null
        this.editValue = null
        this.editPftype = null
        this.editLoginkey = null
        this.editStatus = 1
      }
      if (to !== 2) {
        this.inEdit = false
      }
    },
    onRegionalClick () {
      this.tabIndex = 0
      console.warn('onRegionalClick')
      this.updateSelectedRegional()
    }
  }
}
</script>
<style>
  tr.is-info {
    background: #167df0;
    color: #fff;
  }
  tr.is-danger {
    background: #ff3860;
    color: #fff;
  }
  tr.is-dark {
    background: #363636;
    color: whitesmoke;
  }
  tr.is-primary {
    background: #7957d5;
    color: white;
  }
</style>
